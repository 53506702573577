import { useState, useEffect } from "react";
import Axios from "axios";
import Cookies from 'universal-cookie';


function Subscribe() {


  const [listOfUsers, setListOfUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [day, setDay] = useState([]);
  const [checked, setChecked] = useState(false)
  const cookies = new Cookies();
  const [showMessage, setShowMessage] = useState("")


  var dateVariable = new Date();
  var todayDay = dateVariable.getDate();
  console.log(todayDay);



  useEffect(() => {
    Axios.get("http://localhost:3001/getUsers").then((response) => {
      setListOfUsers(response.data);
    } );
  }, []);


  const createUser = () => {
    Axios.post("http://localhost:3001/createUser", {

      email : email,
      day: todayDay

    }).then((response) => {
      setListOfUsers([...listOfUsers, {

        email : email,

      }]);
      alert("Added");
      setShowMessage(response);

      //cookies.set('myCat', email, { path: '/' });
    });
  };


  const updateUser = () => {
    Axios.post("http://localhost:3001/createUser", {

      email : cookies.get('myCat'),
      day: todayDay

    }).then((response) => {
        console.log("Added update");
    });
  };





  if(cookies.get('myCat')) {

    updateUser();

    return (
      <div>

      <h1>Cookie is stored {cookies.get('myCat')}</h1>
      </div>
    );    
  } else {
    return (
      <div className="App">
        <div className="usersDisplay">
          {listOfUsers.map((user,index) => {


            return (

              <div key={index}>
                <h1>{user.email}</h1>
                <hr />
              </div>

              );
          })}
        </div>

        <div>
          <input type="text" placeholder="Email..." onChange={(event) => {
            setEmail(event.target.value);
          }} />

          <button onClick={createUser}>Create user</button>
          { showMessage && <span>I am a message!</span>}
        </div>



      </div>
    )
  }


}

export default Subscribe;
