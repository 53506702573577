

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PuzzleGame from './puzzlegame/Puzzle';


import Cookies from 'universal-cookie';
import './index.css';






function Puzzle() {


  return (
    <PuzzleGame
    image='https://kariera.lidl.si/images/variations/image_richtext.retina/7/4/9/9/103359947-1-sl_SI/1080x1920_TraineeProdaja.jpeg'
  />
  )

}

export default Puzzle;
