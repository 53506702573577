//import { render } from "react-dom";

import { BrowserRouter as Router, Route ,Link, Routes} from "react-router-dom";
import Subscribe from "./components/subscribe";
import Quiz from "./components/quiz";
import Memory from "./components/memory";
import Puzzle from "./components/puzzle";
import Style from "./assets/style.css";


function App() {



    return (
      <div>

        <Router>
          <Routes>
            <Route exact path="/" element={<h1>Home Page</h1>} />
            <Route exact path="subscribe" element={<Subscribe />} />
            <Route exact path="quiz" element={<Quiz />} />
            <Route exact path="memory" element={<Memory />} />
            <Route exact path="puzzle" element={<Puzzle />} />
          </Routes>

          <div className="list">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="subscribe">Subscribe</Link></li>
              <li><Link to="quiz">Quiz</Link></li>
              <li><Link to="memory">Memory</Link></li>
              <li><Link to="puzzle">Puzzle</Link></li>
              <li><Link to="four">4</Link></li>
            </ul>
          </div>

        </Router>


      </div>
    )

}

export default App;
